<script setup lang="ts">
const appConfig = {
  title: "",
}

const { isMobile } = useDevice()

const route = useRoute()

useHead({
  title: appConfig.title,
})
</script>

<template>
  <v-app-bar app :density="isMobile ? 'compact' : 'default'">
    <v-container class="py-0">
      <v-row class="d-flex align-center">
        <v-col
          :class="`d-flex ${isMobile ? 'justify-space-between' : 'justify-start'}`"
          sm="4"
          cols="6"
          md="3"
        >
          <v-app-bar-nav-icon
            @click="$emit('toggleDrawer')"
            :class="isMobile ? 'justify-start' : 'justify-center'"
          />
          <NuxtLink :to="{ name: 'index' }" class="align-self-center">
            <span class="main-link">
              <img src="@/assets/svg.svg" alt="get signals logo" />
            </span>
          </NuxtLink>
        </v-col>
        <v-col
          sm="8"
          md="6"
          cols="6"
          class="d-flex justify-end"
          v-if="route.name !== 'securities'"
        >
          <SearchSecurity />
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
</template>

<style scoped lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Orbitron&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat&display=swap");

a,
a:visited,
a:hover,
a:active {
  padding-bottom: 4px;
  color: inherit;
  font-family: "Montserrat";
}

.log-btn {
  font-family: "Montserrat";
}

a:not(:active) {
  text-decoration: none;
}

a.router-link-exact-active {
  color: rgb(var(--v-theme-primary));
  border-bottom: 1px solid rgb(var(--v-theme-primary));
}

span.main-link {
  font-family: "Orbitron", sans-serif;
  color: white;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
    margin-right: 8px;
  }
}
</style>
import { $ } from '@effect-app/core/NonEmptySet';
import { md } from 'vuetify/iconsets/md';

