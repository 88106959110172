<script setup lang="ts">
import { mdiCog, mdiFinance, mdiHelp, mdiLogout, mdiWallet } from "@mdi/js"

const sess = useAuth()

// Monitor the access token, if it's about to expire, do a token refresh?
onMounted(() => {
  // TODO: Instead of doing this every hour, do it on api client authentication error
  // and retry the request.. ask the user if we can reload to login?
  setInterval(() => sess.getSession(), 1 * 60 * 60 * 1000)
})

// on refresh error, go login
effect(() => {
  if (sess.data.value?.error === "RefreshAccessTokenError") {
    if (!window.confirm(trans("session.expired"))) {
      return
    }
    void sess.signIn("auth0", undefined, {
      // don't display the login prompt if already logged-in at auth0
      prompt: "",
    }) // Force sign in to hopefully resolve error
  }
})
const isDrawerOpen = ref<boolean>(false)

const user = useOptionalSessionUser()
</script>

<template>
  <v-app>
    <v-navigation-drawer v-model="isDrawerOpen">
      <v-list>
        <v-list-item>
          <NuxtLink :to="{ name: 'portfolio' }">
            <v-icon :icon="mdiWallet" />
            Portfolio
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'securities' }">
            <v-icon :icon="mdiFinance" />
            Securities
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'settings' }">
            <v-icon :icon="mdiCog" />
            {{ trans("body.settings") }}
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink :to="{ name: 'support' }">
            <v-icon :icon="mdiHelp" />
            Support
          </NuxtLink>
        </v-list-item>
        <v-list-item>
          <NuxtLink
            :to="{ name: 'index' }"
            @click="() => (user ? sess.signOut() : sess.signIn('auth0'))"
          >
            <v-icon :icon="mdiLogout" />
            {{ user ? "Logout" : "Login" }}
          </NuxtLink>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
    <HeaderV2 @toggleDrawer="isDrawerOpen = !isDrawerOpen" />
    <v-main>
      <slot />
    </v-main>
  </v-app>
</template>

<style>
* {
  font-family: "Montserrat", sans-serif;
}

a,
a:visited,
a:hover,
a:active {
  padding-bottom: 4px;
  color: inherit;
  font-family: "Montserrat";
  text-decoration: none;
}

a.router-link-exact-active {
  color: rgb(var(--v-theme-primary));
}

.Vue-Toastification__toast--success.signalz-toast {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: rgb(18, 18, 18);
}

.Vue-Toastification__toast--success.signalz-toast * {
  background-color: rgb(var(--v-theme-primary)) !important;
  color: rgb(18, 18, 18);
}
</style>